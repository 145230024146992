<template>
  <div class="pvp overflow-hidden h-100 w-100">
    <base-header
      :title="$t('對戰')"
      :is-title-bold="$isJungo"
      :left-arrow="true"
      :back-on-click-left="false"
      @on-click-left="goHome"
    ></base-header>
    <div class="wrapper p-3 d-flex flex-column">
      <div
        class="wrap-quick-match mb-2 bg-bgsection d-flex flex-column align-items-center justify-content-between"
      >
        <div class="d-flex align-items-center flex-fill">
          <img
            class="img-quick-match"
            :src="require('@/assets/img/pvp/quick-match.svg')"
          />
        </div>
        <div class="w-100">
          <div class="my-3 w-100 bg-white rounded-lg justify-self-start">
            <dropdown
              v-model="quickMatch"
              :title="$t('時限')"
              :options="quickMatchOptions"
            ></dropdown>
          </div>
          <b-button
            class="w-100"
            variant="primary"
            size="fixed-md"
            @click="quickMatchPvp"
            >{{ $t('快速配對') }}</b-button
          >
        </div>
      </div>
      <div
        class="wrap-create-pvp text-primary font-weight-bold d-flex flex-column align-items-center justify-content-center bg-bgsection"
      >
        <div class="d-flex align-items-center flex-fill">
          <img
            class="img-match-friend"
            :src="require('@/assets/img/pvp/match-friend.svg')"
          />
        </div>
        <b-button
          class="w-100"
          variant="primary"
          size="fixed-md"
          @click="createPvp"
          >{{ $t('和朋友下棋') }}</b-button
        >
      </div>
    </div>
    <!-- 快速配對倒數計時 -->
    <modal-timer
      v-if="isQuickMatchShow"
      :sec="60"
      :content-height="175"
      @updateNowSec="updateNowSec"
      @close="isNoMatchShow = true"
    >
      <template v-slot:content>
        <div class="content">
          <img class="img-user-circle" :src="ImageUserCircle" />
          <p class="mt-2 mb-0 text-18 modal-title font-weight-bold">
            {{ $t('尋找對手中') }}
          </p>
          <p class="mt-3 mb-0">{{ nowSec + $t('秒') }}</p>
        </div>
      </template>
      <template v-slot:button>
        <b-button
          variant="primary"
          size="md"
          class="w-100"
          @click="closeQuickMatch"
          >{{ $t('取消') }}</b-button
        >
      </template>
    </modal-timer>
    <!-- 找不到對手modal -->
    <modal-confirm
      v-if="isNoMatchShow"
      :hide-cancel="true"
      :confirm-text="$t('知道了')"
      :has-btn-close="true"
      @confirm="closeQuickMatch"
    >
      <template>
        {{ $t('找不到對手') }}<br />
        {{ $t('配對已取消') }}
      </template>
    </modal-confirm>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import Dropdown from '@/components/Base/Dropdown';
import ModalTimer from '@/components/Modal/ModalTimer.vue';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
import socket from '@/lib/socket/socket.js';
import pvpEnterLobbyMixin from '@/mixin/pvpEnterLobby';
import pvpQuickMatchMixin from '@/mixin/pvpQuickMatch';
import pvpMatchAiMixin from '@/mixin/pvpMatchAi';
import BackEvent from '@/lib/base/backEvent.js';

export default {
  components: {
    BaseHeader,
    Dropdown,
    ModalTimer,
    ModalConfirm,
  },
  mixins: [pvpEnterLobbyMixin, pvpQuickMatchMixin, pvpMatchAiMixin],
  data() {
    return {
      quickMatchOptions: [
        {
          value: 'noHandicap9',
          label: this.$t('{v1}路  - {v2} 分鐘 - {v3}秒{v4}次', {
            v1: 9,
            v2: 5,
            v3: 20,
            v4: 3,
          }),
        },
        {
          value: 'noHandicap13',
          label: this.$t('{v1}路  - {v2} 分鐘 - {v3}秒{v4}次', {
            v1: 13,
            v2: 10,
            v3: 20,
            v4: 3,
          }),
        },
        {
          value: 'noHandicap19',
          label: this.$t('{v1}路  - {v2} 分鐘 - {v3}秒{v4}次', {
            v1: 19,
            v2: 20,
            v3: 30,
            v4: 3,
          }),
        },
      ],
      quickMatch: 'noHandicap9',
      lobbyId: null,
      time: 5,
      byoYomiTime: 10,
      byoYomiAmount: 3,
      boardSize: 9,
      hasHandicap: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters['env/isLoading'];
    },
  },
  watch: {
    quickMatch() {
      switch (this.quickMatch) {
        case 'noHandicap9':
          this.time = 5;
          this.boardSize = 9;
          this.byoYomiTime = 20;
          this.hasHandicap = false;
          break;
        case 'noHandicap13':
          this.time = 10;
          this.boardSize = 13;
          this.byoYomiTime = 20;
          this.hasHandicap = false;
          break;
        case 'noHandicap19':
          this.time = 20;
          this.boardSize = 19;
          this.byoYomiTime = 30;
          this.hasHandicap = false;
          break;
        default:
          this.time = 5;
          this.boardSize = 9;
          this.hasHandicap = false;
          break;
      }
      localStorage.setItem('pvp.latestQuickMatchSetting', this.quickMatch);
    },
  },
  created() {
    const prevQuickMatchSetting = localStorage.getItem(
      'pvp.latestQuickMatchSetting'
    );
    if (prevQuickMatchSetting) {
      this.quickMatch = prevQuickMatchSetting;
    }

    // socket連線重新建立
    socket.once(socket.ResponseEventEnum.REGISTER_SUCCESSFULLY, () => {
      socket.clearEvents([socket.ResponseEventEnum.PVP_EXIST_PVP_GAME]);
      socket.once(socket.ResponseEventEnum.PVP_EXIST_PVP_GAME, (result) => {
        const data = result.data;
        if (data) {
          this.lobbyId = null;
          this.$router.push({name: 'pvp'});
        }
      });
      socket.send({
        action: 'pvp',
        pvpAction: socket.SendEventEnum.PVP_GET_UNFINISHED_GAME,
      });
    });
  },
  mounted() {
    BackEvent.addEvent(() => {
      this.goHome();
    });
  },
  beforeDestroy() {
    socket.clearEvents([socket.ResponseEventEnum.REGISTER_SUCCESSFULLY]);
  },
  methods: {
    goHome() {
      BackEvent.popEvent();
      this.$router.push('/');
    },
    quickMatchPvp() {
      this.getQuickMatch();
      this.isQuickMatchShow = true;
    },
    closeQuickMatch() {
      this.deleteLobby(this.lobbyId);
      this.lobbyId = null;
      this.cancelPvpMatchAiTimeout();
    },
    createPvp() {
      this.$router.push('/pvp-menu');
    },
    async getQuickMatch() {
      if (this.isLoading) {
        return;
      }
      this.$store.commit('env/setIsLoading', true);
      const params = {
        boardSize: this.boardSize,
        hasHandicap: this.hasHandicap,
      };
      try {
        const lobbyId = await this.quickMatchByBoardSize(params);
        if (lobbyId === 'none') {
          const data = {
            boardSize: this.boardSize,
            time: this.time * 60,
            byoYomiTime: this.byoYomiTime,
            byoYomiAmount: this.byoYomiAmount,
            isPublic: true,
            hasHandicap: this.hasHandicap,
          };
          this.createLobby(data);
          this.$store.commit('env/setIsLoading', false);
          this.isQuickMatchShow = true;
        } else if (lobbyId) {
          this.isQuickMatchShow = false;
          this.enterLobby(lobbyId);
          this.$store.commit('env/setIsLoading', true);
        }
      } catch (error) {
        this.isQuickMatchShow = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pvp {
  .wrapper {
    height: calc(100% - 56px);
  }
}
.wrap-quick-match {
  border-radius: 10px;
  padding: 16px;
  height: 50%;
  .img-quick-match {
    width: 88px;
    height: 88px;
  }
}
.wrap-create-pvp {
  border-radius: 10px;
  padding: 16px 16px 45px;
  height: 50%;
  .img-match-friend {
    width: 140px;
    height: 140px;
  }
}
.content {
  color: $font-grayscale-1;
}
</style>
